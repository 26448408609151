/** @format */

import React, {Suspense, useState} from 'react'
import './features/AppLayout/_AppLayout.scss'
import {Redirect, Route, useHistory} from 'react-router-dom'
import {Container} from 'react-bootstrap'
import AppHeader from './features/AppLayout/AppHeader'
import AppSidebar from './features/AppLayout/AppSidebar'
import MainContent from './features/AppLayout/MainContent'
import {Loader} from './shared/components'
import useResponsive from './hooks/useResponsive'
import useAuthCheck from './hooks/useAuthCheck'
import {useAccount} from './hooks/hooks'
import IdleTimer from 'react-idle-timer'

const Login = React.lazy(() => import('./View/Login/Login'))

function App(props) {
	const [collapsed, setCollapsed] = useState(false)
	const [toggled, setToggled] = useState(false)
	const {token, loading, accountUser} = useAuthCheck()
	const {logout} = useAccount()
	let history = useHistory()

	const userDetails = JSON.parse(accountUser)
	// console.log("accountUseraccountUser", accountUser);
	var today = new Date()
	var old = new Date(userDetails?.exp)
	var diffMs = today - old

	var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000)

	// if (diffMins >= 1) {
	// const token = true
	// const token = false
	// const loading = false
	const {isMobile} = useResponsive()

	const handleCollapsedChange = (checked) => {
		setCollapsed(checked)
	}

	const handleToggleSidebar = (value) => {
		if (!collapsed && isMobile) {
			setToggled(false)
			setCollapsed(!collapsed)
		} else {
			setToggled(value)
			setCollapsed(!collapsed)
		}
	}
	const handleOnIdle = (event) => {
		alert('Your token got expired..Please login again.')
		localStorage.removeItem('userToken')
		window.location.href = '/login'
	}

	if (loading) {
		return <Loader />
	}

	if (!token) {
		return (
			<Suspense fallback={<Loader />}>
				{/* <Container className=""> */}
				<Route name='Login' path='/login' exact component={Login} />
				<Route
					name='Home'
					path='/search'
					exact
					render={(props) => {
						return !token ? <Redirect to='/search' /> : <div>hi</div>
					}}
				/>
				{/* </Container> */}
			</Suspense>
		)
	}

	return (
		<>
			{/*** idletimer package to handle handleOnIdle function after 30 minutes of being idle */}
			<IdleTimer timeout={1000 * 60 * 30} onIdle={handleOnIdle} debounce={250} />
			<div className={`app animated fadeIn  ${collapsed ? 'toggled sidebar-collapsed' : ''}`}>
				<AppHeader handleToggleSidebar={handleToggleSidebar} handleCollapsedChange={handleCollapsedChange} toggled={toggled} collapsed={collapsed} />
				<AppSidebar collapsed={collapsed} toggled={toggled} handleToggleSidebar={handleToggleSidebar} />
				<MainContent />
			</div>
		</>
	)
}

export default App
