import axios from "axios";
import ApiEndpoints from "../shared/constants/ApiEndpoints";

axios.defaults.baseURL = "https://pamirdb-reports-pacs-dev.wavelabs.in";
// const baseURL =
  // "http://prod-k8s-node-all-microservices-204e4e60d6fb6172.elb.eu-west-2.amazonaws.com:32078"; // Production enviroment baseURL
const baseURL = "https://pamirdb-reports-pacs-dev.wavelabs.in"; // Dev enviroment baseURL
// const baseURL = "http://10.9.9.196:30649"; // QA enviroment baseURL
// const baseURL = "http://18.133.196.96:30167"; // UAT enviroment baseURL
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    // alert(JSON.stringify(error));
    throw error?.response;
  }
);
/*
axios.interceptors.request.use(request => {
  return request
});

{data:{},
stya:20}
*/

export async function fetchLogin(payload) {
  const endpoint = baseURL + ApiEndpoints.AUTH.LOGIN;
  const response = (await axios.post(endpoint, payload)).data;
  return response;
}

export async function fetchUetrTransaction(payload) {
  const endpoint = baseURL + ApiEndpoints.UETR.TRANSACTION + payload;
  const response = await axios.get(endpoint);
  return response;
}

export async function fetchUetrUnmatch(payload) {
  const endpoint = baseURL + ApiEndpoints.UETR.UNMATCHRECORD + payload;

  const response = await axios.get(endpoint);
  return response;
}

export async function fetchDropdownDomainUnmatch() {
  const endpoint = baseURL + ApiEndpoints.DROPDOWN.DOMAIN;
  const response = await axios.get(endpoint);
  return response;
}

export async function fetchDropdownSchemeUnmatch(payload) {
  const endpoint = baseURL + ApiEndpoints.DROPDOWN.SCHEME + payload;
  const response = await axios.get(endpoint);
  return response;
}

export async function fetchDropdownPaymentUnmatch(payload) {
  const endpoint = baseURL + ApiEndpoints.DROPDOWN.PAYMENT + payload;
  const response = await axios.get(endpoint);
  return response;
}
export async function fetchDropdownMessageUnmatch(payload) {
  const endpoint = baseURL + ApiEndpoints.DROPDOWN.MESSAGE + payload;
  const response = await axios.get(endpoint);
  return response;
}

export async function fetchSearchResult(body, limit, offset) {
  const endpoint =
    baseURL + ApiEndpoints.SEARCH.SEARCHDATA + `${limit}/${offset}`;
  const response = await axios.post(endpoint, body);
  return response;
}

export async function fetchReportCard() {
  const endpoint = baseURL + ApiEndpoints.REPORT.CARD;
  const response = await axios.get(endpoint);
  return response;
}
export async function fetchReportTable() {
  const endpoint = baseURL + ApiEndpoints.REPORT.TABLE;
  const response = await axios.get(endpoint);
  return response;
}
