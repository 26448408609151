import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAsyncFn } from "react-use";
import {
  fetchLogin,
  fetchUetrTransaction,
  fetchUetrUnmatch,
  fetchDropdownDomainUnmatch,
  fetchDropdownSchemeUnmatch,
  fetchDropdownPaymentUnmatch,
  fetchDropdownMessageUnmatch,
  fetchSearchResult,
  fetchReportCard,
  fetchReportTable,
} from "../services/backend-api";
import { authActions } from "../store/Auth/auth.reducer";
import { accountsUserActions } from "../store/AccountsUser/accountUser.reducer";
import useAsyncEffect from "./useAsyncEffect";
import axios from "axios";

export function useLogin() {
  const dispatch = useDispatch();
  const exp = new Date();
  const [loginState, doLogin] = useAsyncFn(async (payload) => {
    //   const response = await login(payload);
    const response = await fetchLogin(payload);
    dispatch(authActions.setToken(response.token));
    dispatch(accountsUserActions.setAccountUserList({ ...response, exp }));
    return payload;
  }, []);
  return [
    {
      error: loginState.error,
      loading: loginState.loading,
      value: loginState.value,
    },
    doLogin,
  ];
}

export function useAccount() {
  const { accountUser, token } = useSelector(
    ({ accountUser: { accountUser }, auth: { token } }) => {
      return {
        accountUser: accountUser || localStorage.getItem("userDetails"),
        token: token || localStorage.getItem("userToken"),
      };
    }
  );
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  // console.log("accountUseraccountUser", accountUser);

  console.log(
    localStorage.getItem("userToken"),
    localStorage.getItem("userDetails")
  );
  useAsyncEffect(async () => {
    if (!token) {
      setLoading(false);
      // dispatch(userActions.setAccountDetails(null));
      return;
    }
    dispatch(accountsUserActions.setAccountUserList(JSON.parse(accountUser)));
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    setLoading(false);
  }, [token]);

  function logout() {
    dispatch(authActions.setToken(null));
  }

  return { accountUser, logout, token, loading };
}

export function useUetr() {
  const { logout } = useAccount();
  const [
    { error: getError, loading: fetching, value: getValue },
    doGetUetrTransaction,
  ] = useAsyncFn(async (id) => {
    const response = await fetchUetrTransaction(id);
    if (response.status === 401) {
      logout();
      return;
    }
    return response.data;
  });

  const [
    {
      error: getUnmatchError,
      loading: fetchingUnmatch,
      value: getValueUnmatch,
    },
    doGetUetrUnmatch,
  ] = useAsyncFn(async (id) => {
    const response = await fetchUetrUnmatch(id);
    if (response.status === 401) {
      logout();
      return;
    }
    return response.data;
  });

  return [
    {
      getError,
      fetching,
      getValue,
      getUnmatchError,
      fetchingUnmatch,
      getValueUnmatch,
    },
    {
      doGetUetrTransaction,
      doGetUetrUnmatch,
    },
  ];
}

export function useDropDown() {
  const { logout } = useAccount();
  const [
    { error: domainError, loading: domainLoading, value: domainValue },
    doGetDomain,
  ] = useAsyncFn(async () => {
    const response = await fetchDropdownDomainUnmatch();
    return response;
  });

  const [
    { error: schemeError, loading: schemeLoading, value: schemeValue },
    doGetScheme,
  ] = useAsyncFn(async (id) => {
    const response = await fetchDropdownSchemeUnmatch(id);
    
    return response;
  });
  const [
    { error: paymentError, loading: paymentLoading, value: paymentValue },
    doGetPayment,
  ] = useAsyncFn(async (id) => {
    const response = await fetchDropdownPaymentUnmatch(id);
    
    return response;
  });

  const [
    { error: messageError, loading: messageLoading, value: messageValue },
    doGetMessage,
  ] = useAsyncFn(async (id) => {
    const response = await fetchDropdownMessageUnmatch(id);
    
    return response;
  });
  return [
    {
      domainError,
      domainLoading,
      domainValue,
      schemeError,
      schemeLoading,
      schemeValue,
      paymentError,
      paymentLoading,
      paymentValue,
      messageError,
      messageLoading,
      messageValue,
    },
    {
      doGetDomain,
      doGetScheme,
      doGetPayment,
      doGetMessage,
    },
  ];
}

export function useSearchCard() {
  const { logout } = useAccount();
  const [
    { error: searchError, loading: searchLoading, value: searchValue },
    doPostSearch,
  ] = useAsyncFn(async (id, limit, offset) => {
    const response = await fetchSearchResult(id, limit, offset);
    if (response.status === 401) {
      logout();
      return;
    }
    return response.data;
  });

  return [
    {
      searchError,
      searchLoading,
      searchValue,
    },
    {
      doPostSearch,
    },
  ];
}

export function useReport() {
  const { logout } = useAccount();
  const [
    { error: cardError, loading: cardLoading, value: cardValue },
    doGetCard,
  ] = useAsyncFn(async () => {
    const response = await fetchReportCard();
    if (response.status === 401) {
      console.log("fetchReportCard in");
      logout();
      console.log("fetchReportCard out");
      return;
    }
    return response.data;
  });
  const [
    { error: tableError, loading: tableLoading, value: tableValue },
    doGetTable,
  ] = useAsyncFn(async () => {
    const response = await fetchReportTable();
    if (response.status === 401) {
      console.log("fetchReportTable in");
      logout();
      console.log("fetchReportTable out");
      return;
    }
    return response.data;
  });

  return [
    {
      cardError,
      cardLoading,
      cardValue,
      tableError,
      tableLoading,
      tableValue,
    },
    {
      doGetCard,
      doGetTable,
    },
  ];
}
