import { combineReducers } from '@reduxjs/toolkit';

import AuthReducer from './Auth/auth.reducer';
import AccountUserReducer from './AccountsUser/accountUser.reducer';

const appReducer = combineReducers({
  auth: AuthReducer,
  accountUser: AccountUserReducer,
});

const rootReducer = (state, action) => {
  if (action?.type === 'auth/logout') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
