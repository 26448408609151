// eslint-disable-next-line import/prefer-default-export
const ApiEndpoints = {
  AUTH: {
    LOGIN: "/validateUser",
  },
  UETR: {
    TRANSACTION: "/transaction/uetr/",
    UNMATCHRECORD: "/unmatchedRecord/uetr/",
  },
  DROPDOWN: {
    DOMAIN: "/masterdata/domain",
    SCHEME: "/masterdata/scheme/",
    PAYMENT: "/masterdata/paymentType/",
    MESSAGE: "/masterdata/messageType/",
  },
  SEARCH: {
    SEARCHDATA: "/search/",
  },
  REPORT: {
    CARD: "/reports/transactionsCount",
    TABLE: "/reports/bicfi",
  },
};

export default ApiEndpoints;
