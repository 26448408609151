import React from "react";
import { ReactComponent as Side } from "./../../assets/img/sideicon.svg";

const nav = [
  // {
  //   title: 'Dashoard',
  //   icon: <Side />,
  //   url: '/dashboard',
  // },
  {
    title: "Search",
    icon: <Side />,
    url: "/search",
  },
  {
    title: "Report",
    icon: <Side />,
    url: "/report",
  },
  // },
  // {
  //   title: 'LoremIpsum',
  //   icon: <Side />,
  //   url: '/loremIpsum',
  // },
];

export default nav;
