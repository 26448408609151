import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAccount } from "./hooks";

const useAuthCheck = () => {
  const history = useHistory();
  const { accountDetails, token, logout, loading, accountUser } = useAccount();

  useEffect(() => {
    const { search } = window.location;
    const params = new URLSearchParams(search);

    if (!token) {
      if (window.location.pathname !== "/reset-password") {
        if (!params.get("key")) {
          history.push("/login");
        } else {
          history.push(`/reset-password?key=${params.get("key")}`);
        }
      }
      logout();
    } else if (window.location.pathname === "/reset-password") {
      if (params.get("key")) {
        history.push("/dashboard");
      }
    }
  }, [accountDetails, token]);
  return { accountDetails, token, loading, accountUser };
};

export default useAuthCheck;
