import React from 'react';
import classnames from 'classnames';
import { Spinner } from 'react-bootstrap';

const Loader = ({ className, variant, size, hideText, text }) => {
  return (
    <div className={classnames('animate__animated animate__fadeIn pt-3 text-center', className)}>
      <Spinner
        as="span"
        animation="border"
        size={size || 'lg'}
        role="status"
        variant={variant || 'primary'}
        aria-hidden="true"
      />
      <h5 className="mt-2">{!hideText && (text || 'Loading')}</h5>
    </div>
  );
};

export default Loader;
