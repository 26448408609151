import React from "react";
import {
  Navbar,
  Button,
  OverlayTrigger,
  Popover,
  Image,
  ListGroup,
  Row,
  Col,
} from "react-bootstrap";
import company_logo from "./../../assets/img/Nucleus_Option 2_v11 PNG.png";
import user_account_icon from "./../../assets/img/user_account_icon.svg";
import Collapse_Open from "./../../assets/img/Collapse_Open.svg";

import dropdown_icon from "./../../assets/img/dropdown_icon.svg";
import { useAccount } from "../../hooks/hooks";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
const AppHeader = ({ handleToggleSidebar, collapsed }) => {
  const { accountDetails, token, logout, loading } = useAccount();
  const userData = useSelector((state) => state.accountUser?.token);
  return (
    <Navbar className="AppHeader" fixed="top" expand="lg">
      <Row style={{ width: "100%" }}>
        <Col className="pr-0" style={{paddingLeft: 0}}>
          {isMobile && (
            <Button
              variant="link"
              className="pl-0 mt-1 text-dark float-left pr-3"
              onClick={handleToggleSidebar}
            >
              {collapsed ? (
                <Image
                  src={Collapse_Open}
                  className="float-left mr-2"
                  style={{ width: "40px", height: "40px" }}
                />
              ) : (
                <Image
                  src={Collapse_Open}
                  className="float-left mr-2"
                  style={{ width: "40px", height: "40px" }}
                />
              )}
            </Button>
          )}
          <Link to={"/search"}>
            <Image
              src={company_logo}
              className="float-left mr-2"
              style={{ width: "170px", height: "60px" }}
            />
          </Link>
        </Col>
        <Col className="p-0">
          <OverlayTrigger
            trigger="click"
            placement="bottom-end"
            rootClose
            // target={target.current}
            overlay={
              <Popover id="popover-contained" style={{ marginTop: "60px" }}>
                <Popover.Content>
                  <ListGroup variant="flush" defaultActiveKey="#link1">
                    <ListGroup.Item
                      // onClick={() => {
                      //   document.body.click();
                      //   setShowProfile(true);
                      // }}
                      className="text-regular"
                      action
                    >
                      Profile
                    </ListGroup.Item>
                    <ListGroup.Item
                      // onClick={() => {
                      //   document.body.click();
                      //   setShowPassword(true);
                      // }}
                      className="text-regular"
                      action
                    >
                      Change Password
                    </ListGroup.Item>
                    <ListGroup.Item
                      onClick={async () => {
                        document.body.click();
                        await logout();
                      }}
                      className="text-regular"
                      action
                    >
                      Sign Out
                    </ListGroup.Item>
                  </ListGroup>
                </Popover.Content>
              </Popover>
            }
          >
            <div style={{marginTop: 6}}>
              <div
                className="float-right row "
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                <div className="user_role">
                  <img src={user_account_icon} alt="" />
                  <span className="user_role_heading"> {userData?.role} </span>
                </div>

                <div className="p-2">
                  <div className="user_name">
                    {userData?.firstName} {userData?.lastName}
                  </div>
                  <div className="user_login_info">{userData?.lastLogin}</div>
                </div>
                <img src={dropdown_icon} alt="" />
              </div>
            </div>
          </OverlayTrigger>
        </Col>
      </Row>
    </Navbar>
  );
};

AppHeader.propTypes = {};

export default AppHeader;
