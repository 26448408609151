import { createSlice } from '@reduxjs/toolkit';

const initialAuth = {
  token: null,
};

const AuthSlice = createSlice({
  name: 'auth',
  initialState: initialAuth,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
      if (!action.payload) {
        localStorage.removeItem('userToken');
      } else {
        localStorage.setItem('userToken', action.payload);
      }
    },
  },
});
export const authActions = AuthSlice.actions;
export default AuthSlice.reducer;
