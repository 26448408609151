import React from 'react';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import nav from './_nav';
import useResponsive from '../../hooks/useResponsive';

// eslint-disable-next-line react/prop-types
const AppSidebar = ({ collapsed, rtl, toggled, handleToggleSidebar }) => {
  const { isMobile } = useResponsive();
  const current = useLocation();
  if (!current) return null;
  return (
    <ProSidebar
      image={false}
      rtl={rtl}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint={isMobile ? 'xs' : 'md'}
      className="AppSidebar dark"
      onToggle={handleToggleSidebar}>
      <SidebarContent>
        <Menu >
          {
            nav.map((menu, idx) =>
                <MenuItem key={menu.url} icon={menu.icon} active={current.pathname.includes(menu.url)} style={{fontSize:'16px',fontWeight:'normal'}}>
                {menu.title}
                <Link to={menu.url} onClick={isMobile ? () => handleToggleSidebar(false) : ''} />
              </MenuItem>
            )}
        </Menu>
      </SidebarContent>
    </ProSidebar>
  );
};

AppSidebar.propTypes = {};

export default AppSidebar;
