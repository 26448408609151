import React from "react";

const DashBoard = React.lazy(() => import("./View/DashBoard/DashBoard.js"));
const Search = React.lazy(() => import("./View/Search/Search.js"));
const Report = React.lazy(() => import("./View/Reports/Report.js"));
const Details = React.lazy(() => import("./View/Search/Search_UETR_Detail.js"));
// const LoremIpsum = React.lazy(() =>import('../View/LoremIpsum'));

const routes = [
  { path: "/", exact: true, name: "Home" },
  // {
  //   path: '/dashboard',
  //   name: 'DashBoard',
  //   exact: true,
  //   component: DashBoard,
  // },
  {
    path: "/search",
    name: "Search",
    exact: true,
    component: Search,
  },
  {
    path: "/report",
    name: "Report",
    exact: true,
    component: Report,
  },
  {
    path: "/search/searchDetail",
    name: "Details",
    exact: true,
    component: Details,
  },
  // { /:id/:id/:id
  //   path: '/searchDetail',
  //   name: 'Details',
  //   component: DashBoard,
  // },
];

export default routes;
