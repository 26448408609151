import React, { Suspense, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Helmet from "react-helmet";
import routes from "../../routes";
import { Loader } from "../../shared/components";
import useAsyncEffect from "../../hooks/useAsyncEffect";

const MainContent = (props) => {
  const [appLoaded, setAppLoaded] = useState(false);
  useAsyncEffect(async () => {
    setAppLoaded(true);
  }, []);
  return (
    <main className="AppContent">
      <Suspense fallback={<Loader />}>
        {appLoaded && (
          <Switch>
            {routes.map(
              (route, idx) =>
                route.component && (
                  <Route
                    key={`route-${idx}`}
                    name={route.name}
                    exact={route.exact}
                    path={route.path}
                    render={(routeProps) => (
                      <>
                        <Helmet>
                          <title>{route.name}</title>
                        </Helmet>
                        <route.component {...routeProps} />
                      </>
                    )}
                  />
                )
            )}

            <Redirect exact path="/login" to="/search" />

            <Route
              path="*"
              name="Not Found"
              render={() => <h2>Page Not Found</h2>}
            />
          </Switch>
        )}
      </Suspense>
    </main>
  );
};

MainContent.propTypes = {};

export default MainContent;
