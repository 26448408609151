import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accountUsers: null,
};

const AccountUserSlice = createSlice({
  name: "accountUser",
  initialState,
  reducers: {
    setAccountUserList: (state, action) => {
      state.token = action.payload;
      if (!action.payload) {
        localStorage.removeItem("userDetails");
      } else {
        localStorage.setItem("userDetails", JSON.stringify(action.payload));
      }
      // state.accountUsers = action.payload;
      // return { accountUsers: action.payload };
      // if (!action.payload) {
      //   localStorage.removeItem("userData");
      // } else {
      //   localStorage.setItem("userData", action.payload);
      // }
      // return { accountUsers: action.payload };
    },
  },
});
export const accountsUserActions = AccountUserSlice.actions;
export default AccountUserSlice.reducer;
